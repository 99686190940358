import { AnyAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import defaultTo from 'lodash/defaultTo';
import isEqual from 'lodash/isEqual';

import type { Address } from '../../../Shared/js/@types/Address';
import type { SuppliersModel } from '../../../Shared/js/@types/SuppliersModel';
import type { RootState } from '../../../Shared/js/redux/rootReducer';
import type { SearchResult } from '../@types/api/SearchResult';
import type { SearchCriteria } from '../@types/SearchCriteria';
import type { WidenResultsArgs } from '../@types/WidenRessultsArgs';

export interface SearchSliceState {
    criteria: SearchCriteria;
    settings: {
        allConnectionsSelected: boolean;
        allContractTerms?: number[];
        coverage: string;
        coverageUrl: string;
        defaultSuppliers?: string[];
        defaultSearchCriteria: SearchCriteria;
        defaultPrepaidExpiryDays?: number;
        hideSupplierFilter: boolean;
        showMaxUpfrontSlider: boolean;
        searchUrl: string;
        resultsSearchCriteria: SearchCriteria;
        pagedResultsUrl: string;
        suppliers: SuppliersModel;
        suppressCoverageCheck: boolean;
        unavailableConnectionTypes: never[];
        embeddedMode: boolean;
        enforceAjax: boolean;
    };
}

const initialState = {} as SearchSliceState;

const sliceName = 'broadband/search';

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        disableMaximumResultLimits(state) {
            state.criteria.common.enforceMaximumResultLimits = false;
            state.settings.enforceAjax = true;
        },

        hideFeaturedResults(state) {
            state.criteria.common.includeFeaturedResults = false;
            state.settings.enforceAjax = true;
        },

        includeNbnConnections(state, action: PayloadAction<string[] | string>) {
            const nbnConnectionTypes = action.payload;
            if (Array.isArray(nbnConnectionTypes)) {
                state.criteria.connectionTypes.values = [
                    ...state.criteria.connectionTypes.values,
                    ...nbnConnectionTypes
                ];
            } else {
                state.criteria.connectionTypes.values.push(nbnConnectionTypes);
            }
        },

        resetBundles(state) {
            state.criteria.bundles = {} as SearchCriteria['bundles'];
        },

        searchError(state) {
            return state;
        },

        searchSuccess(state, action: PayloadAction<SearchResult>) {
            const result = action.payload;

            return produce(state, p => {
                // TODO: Remove 'values' property and use simple array
                // Remove unused isAll and isEmpty fields
                //p.search.criteria.common.suppliers = result.criteria.common.suppliers.values;

                p.settings.suppliers = result.suppliers;
                p.settings.coverage = result.coverage;
                p.settings.unavailableConnectionTypes = result.unavailableConnectionTypes;
                p.settings.allConnectionsSelected = result.allAvailableConnectionsSelected;
                p.settings.hideSupplierFilter = result.hideSupplierFilter;
                p.settings.resultsSearchCriteria = result.criteria;
            });
        },

        updateAddress(state, action: { payload: Address }) {
            if (isEqual(state.criteria.common.address, action.payload)) {
                return state;
            }

            const baseCriteria = state.settings.embeddedMode ? state.settings.defaultSearchCriteria : state.criteria;

            state.settings.enforceAjax = false;

            state.criteria = produce(baseCriteria, draft => {
                draft.common.address = action.payload;

                const defaultSuppliers = state.settings.defaultSuppliers;
                draft.common.suppliers.values = defaultSuppliers && defaultSuppliers.length ? defaultSuppliers : [];

                draft.common.tab = WhistleOut.getSiteConfiguration().broadbandConfig.defaultTab;
                draft.connectionTypes.values = [];
                draft.bundles = {} as SearchCriteria['bundles'];

                const isSingleProvider = state.criteria.common.suppliers.values?.length === 1;
                draft.common.enforceMaximumResultLimits = !isSingleProvider;

                // Preserve original criteria

                draft.speed = state.criteria.speed;
                draft.data = state.criteria.data;
            });
        },

        //changeBundles
        updateBundles(state, action: PayloadAction<SearchCriteria['bundles']>) {
            const bundles = action.payload;
            state.criteria.bundles.homePhone = bundles.homePhone;
            state.criteria.bundles.tv = bundles.tv;
            state.criteria.bundles.lineRental = bundles.lineRental;
            state.criteria.bundles.mobilePhone = bundles.mobilePhone;
        },

        updateConnectionTypes(state, action: PayloadAction<string | string[]>) {
            state.settings.allConnectionsSelected = null;
            state.criteria.connectionTypes.values = Array.isArray(action.payload) ? action.payload : [action.payload];
        },

        // changeContractType
        updateContractType(state, action: PayloadAction<{ contractType: string; hide: boolean }>) {
            const { contractType, hide } = action.payload;
            if (contractType === 'prepaid') {
                return state;
            }

            const contractTerms = defaultTo(parseInt(contractType), null);

            if (hide === true) {
                if (state.criteria.common.contractTerms && state.criteria.common.contractTerms.length > 1) {
                    state.criteria.common.contractTerms = state.criteria.common.contractTerms.filter(
                        c => c !== contractTerms
                    );
                } else {
                    state.criteria.common.contractTerms = state.settings.allContractTerms.filter(
                        c => c !== contractTerms
                    );
                }
            } else {
                state.criteria.common.contractTerms = [contractTerms];
            }
        },

        updateCriteria(state, action: PayloadAction<SearchCriteria>) {
            if (isEqual(state, action.payload)) {
                return state;
            }

            state.settings.allConnectionsSelected = null;
            state.criteria = produce(action.payload, draft => {
                if (state.criteria.modemShortUrl !== draft.modemShortUrl) {
                    draft.common.tab = null;
                }
            });
        },

        // changeData
        updateData(state, action: PayloadAction<number>) {
            if (action.payload === state.criteria.data) {
                return state;
            }
            state.criteria.data = action.payload;
            state.criteria.common.results.productLabel = null;
        },

        //changeModem
        updateModem(state, action: PayloadAction<string>) {
            if (action.payload === state.criteria.modemShortUrl) {
                return;
            }

            state.criteria.modemShortUrl = action.payload;
            state.settings.enforceAjax = true;
        },

        // planSummarySupplierChanged
        updatePlanSummarySupplier(state, action: PayloadAction<{ supplier: string; maxResults: number }>) {
            const { supplier, maxResults } = action.payload;
            state.criteria.common.suppliers.values = supplier ? [supplier] : [];
            state.criteria.common.enforceMaximumResultLimits = supplier ? false : true;
            state.criteria.common.tab = '';
            state.criteria.common.results.maximumNumberOfResults = maxResults;
            state.settings.enforceAjax = true;
        },

        // changeSort
        updateSort(state, action: PayloadAction<{ expression: string; isAscending: boolean }>) {
            const { expression, isAscending } = action.payload;
            state.criteria.common.resultsSortExpression = expression;
            state.criteria.common.resultsSortAscending = isAscending;
            state.settings.enforceAjax = true;
        },

        // changeSpeed
        updateSpeed(state, action: PayloadAction<number>) {
            if (action.payload === state.criteria.speed) {
                return state;
            }
            state.criteria.speed = action.payload;
            state.criteria.common.results.productLabel = null;
        },

        // changeSpend
        updateSpend(state, action) {
            const { minValue, maxValue } = action.payload;

            const commonCriteria = state.criteria.common;
            if (
                (minValue === commonCriteria.minimumSpend || (minValue === -1 && !commonCriteria.minimumSpend)) &&
                (maxValue === commonCriteria.maximumSpend || (maxValue === -1 && !commonCriteria.maximumSpend))
            ) {
                return state;
            }

            commonCriteria.maximumSpend = maxValue;
            commonCriteria.minimumSpend = minValue;
        },

        // changeSupplier
        updateSuppliers(state, action: PayloadAction<string | string[]>) {
            state.criteria.common.suppliers = {
                values: Array.isArray(action.payload) ? action.payload : [action.payload]
            };
            state.settings.enforceAjax = true;
        },

        // changeTab(value) {
        updateTab(state, action: PayloadAction<string>) {
            state.criteria.common.tab = action.payload;
        },

        widenResults(state, action: PayloadAction<WidenResultsArgs>) {
            const options = action.payload;
            if (options.reduceData) {
                state.criteria.data = 0;
            }
            if (options.increasePriceRange) {
                state.criteria.common.maximumSpend = -1;
                state.criteria.common.minimumSpend = 0;
            }
            if (options.includeAllContractLengths) {
                state.criteria.common.contractTerms = [];
            }
            if (options.includeAllSuppliers === true) {
                state.criteria.common.suppliers = { values: [] };
            }
            if (options.reduceSpeed === true) {
                state.criteria.speed = 0;
            }
            if (options.noPeakData === true) {
                state.criteria.peakDataOnly = false;
            }
            if (options.includeMoreConnectionTypes === true) {
                state.criteria.connectionTypes = { values: [] };
            }
            if (options.noDealsOnly === true) {
                state.criteria.common.includeOffersWithCampaignOnly = false;
            }
            if (options.includeAllPrepaidExpiry === true) {
                state.criteria.prepaidExpiry = null;
            }
            if (options.removeSelectedModem === true) {
                state.criteria.modemShortUrl = null;
                state.criteria.simOnly = false;
            }
            if (options.noUploadCounted === true) {
                state.criteria.uploadNotCounted = false;
            }
            if (options.noStaticIp === true) {
                state.criteria.staticIp = false;
            }
            if (options.noBundling === true) {
                state.criteria.bundles.tv = null;
                state.criteria.bundles.homePhone = null;
                state.criteria.bundles.lineRental = null;
                state.criteria.bundles.mobilePhone = null;
            }
            if (options.sortBy === true) {
                state.criteria.common.resultsSortExpression = null;
                state.criteria.common.resultsSortAscending = true;
            }
        }
    }
});

export const actions = slice.actions;

const extraSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(slice.actions.searchSuccess, (state, action: PayloadAction<SearchResult>) => {
            slice.reducer(state, actions.updateCriteria(action.payload.criteria));
        });
    }
});

export default function broadbandReducer(state: SearchSliceState, action: AnyAction) {
    return extraSlice.reducer(slice.reducer(state, action), action);
}

export const selectCriteria = createSelector(
    (state: RootState) => state,
    state => state.broadband.search.criteria
);
